import React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bulma-components";
import {fetchAddUserToReview, fetchParticipants} from "../redux/inquiries";
import {RouteComponentProps, withRouter} from "react-router";

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {
  type: string
}

type AddUserToReviewModalProps = RouteComponentProps<PathParamsType> & {
  data: any,
  close: () => void,
  fetchParticipants: (slug: string) => Promise<void>
}

type AddUserToReviewModalState = {
  availability: string,
}

class AddUserToReviewModal extends React.Component<AddUserToReviewModalProps, AddUserToReviewModalState> {
  constructor(props) {
    super(props);
    this.state = {
      availability: ""
    };
  }

  async addUserToReview() {
    await fetchAddUserToReview({
      userId: this.props.data.id,
      availability: this.state.availability
    });
    await this.props.fetchParticipants(this.props.match.params.type);
  };

  render() {
    return (
      <Modal.Card>
        <Modal.Card.Head onClose={this.props.close} showClose>
          <Modal.Card.Title>
            Вы уверены что хотите добавить
            <b>{this.props.data.firstName} {this.props.data.lastName}</b> задним числом на ревью?
          </Modal.Card.Title>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <div className="field">
            <label htmlFor="drop-reason"><b>Доступность</b></label>
            <div className="control">
              <input
                id="drop-reason"
                className="input is-black"
                type="text"
                onChange={(event) => this.setState({ availability: event.target.value })}
              />
            </div>
          </div>
        </Modal.Card.Body>
        <Modal.Card.Foot style={{ alignItems: "end", justifyContent: "end" }}>
          <Button onClick={() => this.props.close()}>
          Отмена
          </Button>
          <Button
            color="black"
            onClick={async () => {
              await this.addUserToReview();
              this.props.close();
            }}
          >
            Отправить
          </Button>
        </Modal.Card.Foot>
      </Modal.Card>
    );
  }
}

const mapStateToProps = (state) => ({
  columns: state.users.columns,
  columnsToDisplay: state.users.columnsToDisplay,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchParticipants
  })(AddUserToReviewModal)
);
